<template>
  <div class="recommendGoodsMain">
    <nav>
      <h4>{{ $fanyi("给你的建议") }}</h4>
      <div class="iconBox">
        <img
          src="../../assets/icon/flecha-izquierda.svg"
          alt=""
          @click="shopCarouselChange('left')"
        />
        <img
          src="../../assets/icon/flecha-izquierda-1.svg"
          alt=""
          @click="shopCarouselChange('right')"
        />
      </div>
    </nav>
    <div v-if="goodsList.length == 0">
      <el-carousel
        trigger="click"
        ref="shopCarousel"
        :interval="3000"
        :autoplay="autoplay"
        @mouseover.native="autoplay = false"
        @mouseleave.native="autoplay = true"
        height="342px"
      >
        <el-carousel-item>
          <div class="SearchConterCrosswise_public skeleton">
            <div class="SearchConterCrosswiseCon" v-for="i in 6" :key="i">
              <div class="imageBox">
                <div class="notHaveData">
                  <i class="el-icon-loading"></i>
                </div>
              </div>
              <div class="skeletonBOx">
                <el-skeleton animated> </el-skeleton>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div v-else>
      <el-carousel
        trigger="click"
        ref="shopCarousel"
        :interval="3000"
        :autoplay="autoplay"
        @mouseover.native="autoplay = false"
        @mouseleave.native="autoplay = true"
        height="342px"
      >
        <el-carousel-item
          v-for="(item, index) in goodsList"
          :key="index"
          v-show="item.status"
        >
          <div class="SearchConterCrosswise_public">
            <router-link
              v-for="goodsItem in item"
              :key="goodsItem.goods_id"
              class="SearchConterCrosswiseCon"
              :to="'/ProductDetails?goods_id=' + goodsItem.goods_id"
              target="_blank"
            >
              <div class="SearchConterCrosswiseImg">
                <el-image :src="goodsItem.imgUrl" class="goodsImg">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                  <div slot="placeholder">
                    <i class="el-icon-loading"></i>
                  </div>
                </el-image>
              </div>
              <div class="rests">
                <p class="goodsTitle" :title="goodsItem.title">
                  {{ goodsItem.title }}
                </p>
                <p>
                  <span>
                    {{
                      $fun.EURNumSegmentation(
                        $fun.ceil(
                          goodsItem.goodsPrice * $store.state.exchangeRate
                        )
                      )
                    }}€
                  </span>
                  {{ $fun.RMBNumSegmentation(goodsItem.goodsPrice) }}￥
                </p>
              </div>
            </router-link>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div v-if="$route.name != 'cart'" class="lookMore">
      <div
        class="imgBox"
        @click="
          $fun.routerToPage(
            '/CommoditySearch?keyword=' +
              translation_name +
              '&CN=' +
              $fun.toCode(chinese_name)
          )
        "
      >
        <img :src="require('@/assets/hometop/flechacategorias.svg')" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      goodsList: [],
      autoplay: true,
      translation_name: "",
      chinese_name: "",
    };
  },
  components: {},
  computed: {},
  created() {
    this.getPersonalizedGoods();
  },
  methods: {
    // 获取推荐商品数据
    getPersonalizedGoods() {
      this.$api.getPersonalizedGoods().then((res) => {
        this.goodsList = res.data.result;
        this.chinese_name = res.data.chinese_name;
        this.translation_name = res.data.translation_name;
        this.ProcessingShopData();
      });
    },
    // 数据处理
    ProcessingShopData() {
      //console.log(23);
      let lack = 6 - (this.goodsList.length % 6); //取得店铺商品数离6个的倍数差多少个商品的数量
      //console.log("缺多少个商品", lack);
      //差多少个就从最前面取多少个填充到最后
      for (let i = 0; i < lack; i++) {
        this.goodsList.push(this.goodsList[i]);
      }
      //  分成六个一组
      this.goodsList = this.$fun.changeArr(this.goodsList, 6);
      //console.log(this.goodsList);
      this.$forceUpdate();
    },
    // 滑动店铺商品列表
    shopCarouselChange(type) {
      if (type == "left") {
        this.$refs.shopCarousel.prev();
      } else if (type == "right") {
        this.$refs.shopCarousel.next();
      }
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "../../css/mixin.scss";

.recommendGoodsMain {
  width: $pageWidth;
  margin: 0 auto;
  margin-bottom: 0px;

  nav {
    height: 55.88px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      color: black;
      font-size: 21px;
      font-weight: 600;
    }

    .iconBox {
      img {
        width: 40.88px;
        height: 40.88px;

        &:last-child {
          margin-left: 20px;
          margin-right: 30px;
        }

        opacity: 0.45;

        &:hover {
          opacity: 0.75;
        }
      }
    }
  }

  .recommendGoodsBox {
    display: flex;

    .recommendGoods {
      cursor: pointer;
      width: 213.31px;
      padding: 15px;
      height: 290px;
      background-color: white;
      border: #e8e8e8 solid 1px;
      border-radius: 6px;
      margin: 10px;
      transition: 0.3s;

      .imageBox {
        width: 100%;
        height: 187.31px;
        margin-bottom: 5px;
        text-align: center;
        background-color: white;

        /deep/.el-image {
          $imgSize: 181.31px;
          width: $imgSize;
          height: $imgSize;
          transition: 0.3s;
          text-align: center;

          i {
            font-size: $imgSize/4;
            line-height: $imgSize;
          }
        }
      }

      .goodsTitle {
        height: 39px;

        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.5;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 5px;
        color: #404040;
        font-family: "Open Sans", Sans-serif;
        font-size: 13px;
        font-weight: 500;
      }

      .priceBox {
        color: #ffa200;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.6em;
      }

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 0px 15px rgb(223, 223, 223);
      }
    }
  }

  .lookMore {
    text-align: right;
    color: #1a289d;

    .imgBox {
      padding: 22px 34px;
      display: inline-flex;
      cursor: pointer;

      img {
        width: 36px;
        height: 36px;
      }
    }

    i {
      font-weight: 800;
      font-size: 14px;
    }
  }
}

/deep/.el-carousel__arrow--right,
/deep/.el-carousel__arrow--left {
  display: none;
  width: 100px;
  height: 100px;
  background: #ffffff;
  opacity: 0.6;
  border-radius: 50%;
}

/deep/ .el-carousel__arrow {
  background: rgba(255, 255, 255, 0.5);

  i {
    color: $homePageSubjectColor;
    font-size: 60px;
    font-weight: 700;
  }
}

/deep/.el-carousel__button {
  display: none;
  width: 12px;
  height: 12px;
  background: #ffffff;
  border-radius: 50%;
}

/deep/.el-carousel__indicator.el-carousel__indicator--horizontal.is-active
  .el-carousel__button {
  outline: 3px solid $homePageSubjectColor;
  background-color: transparent;
}

.notHaveData {
  $imgSize: 214px;
  width: $imgSize;
  height: $imgSize;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: $imgSize/4;
  }
}
.skeleton {
  height: 320px;
  .skeletonBOx {
    padding: 0 20px;
  }
}
.SearchConterCrosswise_public {
  width: $pageWidth;
  // height: 340px;
  display: flex;
  margin: 0 auto 25px;
  padding-top: 20px;
  .SearchConterCrosswiseCon {
    border-radius: 16px;
  }
}
</style>
